import React, { useEffect, useContext, useState, useRef } from 'react'
import axios from "axios";
import { getStorageItem } from '../../utils/sessionStorage';
import { useNavigate } from 'react-router-dom';
import './AdminSection.scss'
import champHuntIcon from '../../assets/images/postimg.png'
import { FaMicrophone, FaStop } from 'react-icons/fa';
import dummyUser from '../../assets/images/flag.png'
import filterIcon from '../../assets/images/Filter.png'
import threeDots from '../../assets/images/threedot_Icon.png'
import searchIcon from '../../assets/images/search_Icon.png'
import sendIcon from '../../assets/images/Send_Icon.png'
import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { Message } from '@mui/icons-material';
import uploadFileChat from '../../assets/images/paperClipLink.png';
import { SocketContext } from "../../context/SocketContext";

import useAudioRecorder from './useAudioRecorder';


export default function AdminSectionChat() {
    const accessToken = getStorageItem('token');
    const userId = getStorageItem('user_id');
    const [usersUnseenMessage, setUsersUnseenMessage] = useState([])
    const [userProfileId, setUserProfileId] = useState('')
    const userProfileIdRef = useRef(userProfileId)
    const [userName, setUserName] = useState('User')
    const [userProfilePhoto, setUserProfilePhoto] = useState('')
    const [userChats, setUserChats] = useState([])
    const [adminTypedMessage, setAdminTypedMessage] = useState('')
    const [cachedResult, setCachedResult] = useState('')
    const [userAdminChat, setUserAdminChat] = useState([]);
    const [mediaType, setMediaType] = useState(null)
    const [mediaFile, setMediaFile] = useState(null);
    const [searchUserName, setSearchUserName] = useState('')
    const [usersList, setUsersList] = useState([])
    const [selectedImage, setSelectedImage] = useState(null);
    const chatPersonIndex = useRef(null);
    const [socket] = useContext(SocketContext)

    const { isRecording, isRecordingSuccess, setIsRecordingSuccess, audioFile, setAudioFile, startRecording, stopRecording } = useAudioRecorder();

    const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
    }

    const setSeencheck = async (chatId) => {
        let type = 'Admin';
        if (!chatId) return;

        const onlineuser = {
            method: 'POST',
            url: global.config.ROOTURL.prod + '/chat/isSeenCheck',
            headers: {
                Authorization: 'Bearer ' + accessToken,
                'Content-Type': 'application/json',
            },
            data: {
                sendertype: type,
                userid: chatId,
            },
        };

        await axios(onlineuser)
            .then((data) => { })
            .catch((error) => {
                console.log(error);
            });
    };


    const handleData = (user, index) => {
        setUserProfileId(user._id)
        setUserProfilePhoto(user.profilePhoto)
        setUserName(user.firstName + " " + user.lastName)
        chatPersonIndex.current = index
        setUsersUnseenMessage(prevItems => {
            const updatedItems = [...prevItems]
            updatedItems[index] = {...updatedItems[index], seen:true}
            return updatedItems
        })
        setSeencheck(user._id)
    }

    const handleStopClick = () => {
        stopRecording()
        setMediaType('audio');
        setMediaFile(audioFile);
    }

    const sendMessage = async () => {
        if (adminTypedMessage === '' && mediaType === null) {
            return;
        }

        const messageDetails = { sendertype: 'Admin', userid: userProfileId, mediatype: mediaType, message: adminTypedMessage }

        if (mediaType) {
            let dataURL = ''
            const formData = new FormData()
            formData.append('uploader', mediaFile)
            const uploadResult = await axios.post(global.config.ROOTURL.prod + '/upload-file',
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "multipart/form-data",
                    },
                })
            if (uploadResult.data && uploadResult.data.length > 0) {
                dataURL = uploadResult.data[0].location
                setSelectedImage(null)
                setTimeout(() => {
                    setAudioFile(null)
                }, [3000])
            }
            messageDetails.message = dataURL
        }
        socket?.emit('send-msg', messageDetails)
        //socket.emit('send-msg',messageDetails)
        //setUserAdminChat((prevMessages) => [messageDetails,...prevMessages])
        setMediaType(null)
        setMediaFile(null)
        setAdminTypedMessage('')
        setIsRecordingSuccess(false)
    }

    const handleUpload = (event) => {
        const file = event.target.files[0]
        setSelectedImage(file.name)
        setMediaFile(file)
        if (file.type.startsWith('video')) {
            setMediaType('video')
        }
        if (file.type.startsWith('image')) {
            setMediaType('image')
        }
    }


    const handleSearchUserName = (event) => {
        setSearchUserName(event.target.value)
    }

    const navigate = useNavigate()
    const [items, setItems] = useState([]);
    const [Messages, setMessages] = useState(true);
    const [olderItem, setOlderItem] = useState([]);


    const handleuserChat = (id) => {
        navigate(`/profile/chat/${id}`)
    }
    const showUserProfile = () => {
        if (!userProfileId) {
            return;
        }
        navigate(`/profile/${userProfileId}`)
    }
    const handleMessage = () => {
        setMessages(!Messages);
    }

    const fetchUserUnseenMessagesData = async () => {
        const onlineuser = {
            method: "GET",
            url: global.config.ROOTURL.prod + `/chat/getNotSeenMessage`,
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/json",
            },
        };
        await axios(onlineuser)
            .then((result) => {
                setUsersList(result.data)
                setUsersUnseenMessage(result.data)
            }).catch((err) => {
                console.log(err)
            })
    }

    // const fetchData2 = async () => {
    //     const onlineuser = {
    //         method: "GET",
    //         url: global.config.ROOTURL.prod + `/chat/getOldAdminMessages`,
    //         headers: {
    //             Authorization: "Bearer " + accessToken,
    //             "Content-Type": "application/json",
    //         },
    //     };
    //     await axios(onlineuser)
    //         .then((response) => {
    //             setOlderItem(response.data);
    //         }).catch((error) => {
    //             console.log(error);
    //         })

    // };

    // const fetchUsersChat = async () => {
    //     const response = await axios.get(`http://localhost:3002/chat/getUserAdminChat/${userProfileId}`, headers)
    //     console.log("ADMIN CHATS", response.data)
    //     setUserChats(response.data)
    // }

    const fetchUserAdminChat = async () => {
        const result = await axios.get(global.config.ROOTURL.prod + `/chat/getUserAdminChat/${userProfileId}`, { headers })
        setUserAdminChat(result.data)
    }

    useEffect(() => {
        if (socket && userId) {
            socket.on('connected', () => {
                console.log("Successfully connected to the socket");
            });
            socket.on('receive-msg', (data) => {
                if (data.userid === userProfileIdRef.current) {
                    setUserAdminChat((prevMessages) => [data, ...prevMessages])
                    console.log("chat person index", chatPersonIndex.current)
                    setUsersUnseenMessage(prevState => {
                        const updatedArray = [...prevState]

                        const [item] = updatedArray.splice(chatPersonIndex.current, 1);

                        const updatedItem = { ...item, message: data.message }

                        updatedArray.unshift(updatedItem)
                        chatPersonIndex.current = 0;
                        return updatedArray
                    })
                }
            });

            return () => {
                socket.off('connected');
                socket.off('receive-msg');
            };
        }
    }, [socket, userId]);

    useEffect(() => {
        fetchUserUnseenMessagesData();
    }, [])

    useEffect(() => {
        //fetchUsersChat();
        fetchUserAdminChat()
        userProfileIdRef.current = userProfileId
    }, [userProfileId])
    // useEffect(()=>{
    //     socket.on('receive_message',(message)=>{
    //         setReceivemsg(prev=>{
    //             return [message,...prev]
    //         })
    //     })
    // },[])
    useEffect(() => {
        const filtered = usersList.filter(data => {
            const firstName = data.firstName || ''
            const lastName = data.lastName || ''
            const fullName = `${firstName}${lastName}`
            if (fullName.toLowerCase().includes(searchUserName.toLowerCase())) {
                return true
            }
            return false
        })
        setUsersUnseenMessage(filtered)
    }, [searchUserName])

    return (
        <Box sx={{ width: '100%', height: '100%', overflowY: 'hidden' }}>
            <div className='adminChatSection_Header'>
                <div>
                    <p style={{ fontSize: "28px", fontWeight: 500 }}>All Chats</p>
                </div>

                <div className='userProfile_AdminChatSection'>
                    <Typography variant='p' style={{ fontSize: '14px', marginRight: '6px' }}>Champhunt Admin</Typography>
                    <img style={{ width: "26px", height: "26px" }} src={champHuntIcon} alt='adminIcon' />
                </div>
            </div>
            <Grid container spacing={2}>
                <Grid item xs={3.8} style={{ borderRight: '1px solid #AFB8CF', marginTop: '16px' }}>
                    <div className='allMessage_Title'>
                        <p style={{ fontSize: "18px", fontFamily: "inter", fontWeight: 500 }}>All Message</p>
                        <img src={filterIcon} alt='FilterIcon' />
                    </div>
                    <div style={{ borderBottom: "1px solid #AFB8CF" }}>
                        <input style={{ width: "92%", padding: "10px 17px", border: "none", fontSize: "14px", borderRadius: "8px", backgroundColor: '#F8F9FD', margin: '12px' }} placeholder='Search or start a new chat' onChange={handleSearchUserName} />
                    </div>

                    <div style={{ height: '70vh', overflowY: 'scroll' }}>
                        {usersUnseenMessage.map((data, index) => (
                            <div key={index} className='allUser_Section' onClick={() => { handleData(data, index) }}>
                                <div className='userProfile_context'>
                                    <div style={{display:"flex"}}>
                                        <div>
                                            <img className='profileicon_Section' src={data.profilePhoto} />
                                        </div>

                                        <div className='userNameData_Section'>
                                            <p className='userName_Profile'>{data.firstName + " " + data.lastName}</p>
                                            <p className='userData_Profile'>{data.mediatype === 'image' ? "Photo" : data.mediatype === 'video' ? "Video" : data.message}</p>
                                            <span className='messageTime_Profile'>{`${moment(data.latestCreatedate).format('ll')} | ${moment(data.latestCreatedate).format('LT')}`}</span>
                                        </div>
                                    </div>

                                    {!(data?.seen)  && <div className="unseenMessage-notification"></div>}
                                </div>
                            </div>
                        ))}
                    </div>
                </Grid>
                <Grid item xs={8.2} style={{ paddingLeft: "0px" }}>
                    <div className='chatSection'>
                        <div className='userChatDisplay' onClick={showUserProfile}>
                            <img className='userProfileImage' src={userProfilePhoto} />
                            <p style={{ paddingLeft: '6px' }}>
                                {userName}
                            </p>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <img className='icon_css' src={searchIcon} />
                            <img className='icon_css' src={threeDots} />
                        </div>
                    </div>

                    <div id='style-2' style={{ display: 'flex', flexDirection: 'column-reverse', overflowY: 'scroll', height: '70vh', backgroundColor: '#F9F9FC' }}>

                        {userAdminChat.map((chat, index) => (
                            <>
                                <span className={chat.sendertype === 'Admin' ? "sendTime" : "receivedTime"}>{moment(chat.createdDate).format('LT')}</span>
                                {chat.mediatype === null && (
                                    <div key={index} className={chat.sendertype === 'Admin' ? "send" : "received"}>{chat.message}</div>
                                )}
                                {chat.mediatype === 'image' && (
                                    <img key={index} src={chat.message} alt="Screenshot" className={chat.sendertype === 'Admin' ? "send" : "received"} style={{ width: "40%", height: '40%', margin: "4px 16px", backgroundColor: 'none' }} />
                                )}
                                {chat.mediatype === 'video' && (
                                    <video key={index} src={chat.message} controls className={chat.sendertype === 'Admin' ? "send" : "received"} style={{ width: "40%", height: '40%', margin: '4px 16px' }} autoPlay loop></video>
                                )}
                                {chat.mediatype === 'audio' && (
                                    <div className={chat.sendertype === 'Admin' ? "send" : "received"} style={{
                                        width: '100%',
                                        maxWidth: '300px',
                                        display: "flex",
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }} key={index}>
                                        <audio key={index} controls controlsList="nodownload" >
                                            <source src={chat.message} type="audio/wav" key={index} />
                                            Your browser does not support the audio element.
                                        </audio>
                                    </div>
                                )}

                            </>
                        ))}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Box>
                            <a href="#">
                                <label htmlFor="file-upload">
                                    <img src={uploadFileChat} alt="Upload" style={{ cursor: 'pointer', width: '40px', height: '40px', objectFit: 'cover', margin: '0px 6px' }} />
                                </label>
                            </a>
                            <input
                                id="file-upload"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={handleUpload}
                            />
                        </Box>
                        {selectedImage && (
                            <span style={{
                                position: 'absolute',
                                top: '52%',
                                left: '32%',
                                backgroundColor: 'antiquewhite',
                                padding: '5px'
                            }}>{selectedImage}</span>
                        )}
                        {/* {isRecording ? (
                            <FaStop
                                style={{ height: '25px', width: '25px', marginRight: '10px', cursor: 'pointer' }}
                                onClick={handleStopClick}
                            />
                        ) : (
                            <FaMicrophone
                                style={{ height: '25px', width: '25px', marginRight: '10px', cursor: 'pointer' }}
                                onClick={startRecording}
                            />
                        )} */}
                        {/* <audio controls>
                            <source src='"https://champhunt-file-save-app-dev2.s3.ap-south-1.amazonaws.com/audio_6_11_2024_1_21_PM.wav"' type="audio/wav" />
                            Your browser does not support the audio element.
                        </audio> */}
                        <input style={{ display: 'flex', width: '90%', margin: '10px 10px', border: '1px solid #ddd', borderRadius: "8px", backgroundColor: '#F8F8F8', padding: '10px 15px', }} placeholder={isRecording ? 'Recording in progress...' : isRecordingSuccess ? 'Recording successfull press send button' : 'Type a Message'} value={adminTypedMessage} onChange={(e) => { setAdminTypedMessage(e.target.value) }} onKeyPress={(e) => { if (e.key === 'Enter') { sendMessage(); } }} />
                        <img style={{ height: '40px', width: '40px' }} src={sendIcon} alt='sendIcon' onClick={sendMessage} />

                    </div>
                </Grid>
            </Grid>
        </Box>
        /*

                <div className='MainChatContainer'>
                    <div className='chatSection'>
                        <div className='userChatDisplay'>
                            <img className='userProfileImage' src={dummyUser} />
                            <p>
                                Jay Mawari
                            </p>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img className='icon_css' src={searchIcon} />
                            <img className='icon_css' src={threeDots} />
                        </div>
                    </div>
                    <div style={{ height: '72vh' }}>
                        Message Section
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', backgroundColor:'#eeeeee' }}>
                        <input style={{ display: 'flex', width: '84%', margin: '10px 10px', alignItems: 'center', border: '1px solid #ddd', padding: '10px 15px', backgroundColor: '#ffffff' }} placeholder='Search' />
                        <img style={{ height: '40px', width: '40px' }} src={sendIcon} alt='sendIcon' />
                    </div>
                </div>
            </div>
        </div>*/
    )
}
