import React from "react";
import { Route, Routes } from "react-router-dom";
import { appRoutes } from "../../constants/appConstants";
import { AdCountProvider } from "../../components/Rewards/Context";
import { AddressProvider } from "../../marketplace/addressPage/addressContext";

import AuthGuardRoute from "../AuthGuard";
import UnAuthGuardRoute from "../UnAuthGuard";
import UnAuthContainer from "../UnAuthContainer";
import AuthContainer from "../AuthContainer";
import AdminCreateSubscription from "../../containers/subscription/create/index";
import AdminSubscription from "../../containers/subscription/get_subscriptions/index";
import Onboarding from "../../containers/onboarding";
import PitchesPage from "../../containers/pitch/index";
import PitchPage from "../../containers/pitch/pitch-page";
// import LandingPitch from "../../containers/Landing-pitch/index";
import SearchPage from "../../containers/search";
import AdminDashboard from "../../containers/admin";
import AllEvents from "../../containers/all-events";
import EventsCreate from "../../containers/events-create";
import FindEvent from "../../containers/find-event";
import FindAllEvents from "../../containers/find-all-events";
import SingleEvent from "../../containers/single-event";
import EditEvent from "../../containers/edit-event-admin";
import UpdateEvent from "../../containers/update-event";
import DeleteEvent from "../../containers/delete-event";
import EventRegistration from "../../containers/event-registration";
import RegistrationSuccessful from "../../containers/event-registration/registrationSuccessful";
import RegistrationWelcome from "../../containers/event-registration/registrationWelcome";
import DealsPage from "../../containers/deals";
import AboutUs from "../../containers/aboutus";
import Jobs from "../../containers/jobs";
import OwnProfileDetailsPage from "../../containers/profile/index";
import ActivateUserPage from "../../containers/activateuser";
import SuggestionsPage from "../../containers/suggestions/SuggestionsPage";
import UserCount from "../../containers/user-count";
import ReferralPage from "../../containers/referral";
import NotificationPage from "../../containers/notification";
import EditProfile from "../../containers/editProfile";
import LandingPage from "../../containers/landingPage";
import NewLandingPage from "../../containers/newLandingPage";
import VideosList from "../../components/commentary/videosList";
import AdminCreateCommentary from "../../containers/commentary/index";
import RecordVoice from "../../components/commentary/recordVoice";
import AdminVideos from "../../containers/commentary/adminVideos";
import UserVideos from "../../containers/commentary/userVideos";

//Subscription and Payment Gateway
import SubsCription from "../../components/subs-cription/index";
import Payment from "../../components/subs-cription/payment-status";

import ActiveCoupons from "../../components/coupons/activeCoupons";
import AddCoupon from "../../components/coupons/addCoupon";

import ArticlePage from "../../components/news/articles";
import SignIn from "../../components/signin-signup/signin/signin.component";
import SignUp from "../../components/signin-signup/signup/signup.component";
import NotFound from "../../components/notfound";
import ResetPassword from "../../components/signin-signup/fgt-pwd/resetpwd.component";
import ForgotPassword from "../../components/signin-signup/fgt-pwd/fgtpwd.component";
import PostedJob from "../../components/posted-job";
import PostJob from "../../components/post-job";
import EasyApply from "../../components/easy-apply";
import JobDescription from "../../components/job-description";
import MyJob from "../../components/my-job";
import SavedJob from "../../components/saved-job";
import Hashtags from "../../containers/hashtags";
import Privacy from "../../components/privacy/privacy";
import RefundPolicy from "../../components/policy/NewRefundPage";
import Rules from "../../components/rules/rules";
import Leaderboard from "../../components/leader-board/index";
import { useSelector } from "react-redux";
import CampaignLandingPage from "../../components/campaign-landing-page";
import ManageCarousel from "../../containers/all-carousel";
import SecoundLandingPage from "../../components/Landingpage2/index";
import Highlights from "../../components/Highlights/Highlights";

import CarouselCreate from "../../containers/carousel-create";
import FindCarousel from "../../containers/find-carousel";
import ManageDeal from "../../containers/all-deals";
import DealCreate from "../../containers/deal-create";
import FindDeal from "../../containers/find-deal";
import UpdateDeal from "../../containers/update-deal";
import EditDeal from "../../containers/edit-deal-admin";
import RedeemHistory from "../../containers/redeem-history";
import DeletedDeal from "../../components/admin/deals/deletedDeals";
import AllJobsAdmin from "../../containers/all-jobs-admin/index";
import FindOneJobAdmin from "../../containers/all-jobs-admin/findOneJob";
import AppliedUserList from "../../containers/all-jobs-admin/appliedUserList";
import Treanding from "../../containers/hashtags/Treanding";
import ManageDefaultUsers from "../../containers/default-users";
import RefferedUsers from "../../containers/refferedUsers";

import Contest from "../../containers/contest";
import ContestCreate from "../../containers/contest/create";
import ContestAddQuestion from "../../containers/contest/add-question";
import ContestEditAnswer from "../../containers/contest/edit-answer";
import ContestViewQuestions from "../../containers/contest/view-questions";
import ContestUpdate from "../../containers/contest/update";
import ContestAnswers from "../../containers/contest/answers";
import ContestResults from "../../containers/contest/results";
import ContestResultList from "../../containers/contest/list";
import RefferedToday from "../../containers/refferedUsers/reffered-today";
import AllSellers from "../../containers/all-sellers";
import AllProducts from "../../containers/all-products";
import SingleSeller from "../../containers/single-seller";
import IndVSNz from "../../commons/Events/IndVSNz";
import FindUser from "../../containers/find-user";
import UspLandingpage from "../../components/UspLandingpage";
import Dummy from "../../components/Dummy";
import PostPreview from "../../components/preview/postPreview";
import JobPreview from "../../components/preview/JobPreview";
import EventPreview from "../../components/preview/EventPreview";
import HeaderGuest from "../../components/header-guest/index";
import LandingPitch from "../../containers/Landing-pitch/index";
import PostGuest from "../../components/post-guest/index";
import ProfilePreview from "../../components/preview/profilePreview";
import Dashboard from "../../containers/Dashboard";

//Fantasy-Players
import FantasyPlayers from "../../containers/fantasy-players";
import FantasyPlayersCreate from "../../containers/fantasy-players/create";

//Fantasy
import Fantasy from "../../containers/fantasy";
import FantasyCreate from "../../containers/fantasy/create";
import FantasyAddPlayer from "../../containers/fantasy/players/add";
import FantasyViewPlayer from "../../containers/fantasy/players/view";

// Marketplace
import MarketPlace from "../../marketplace";
import Cart from "../../marketplace/cart/index";
import Popular from "../../marketplace/popular";
import Trending from "../../marketplace/trending";
import New from "../../marketplace/new";
import Category from "../../marketplace/category";
import Single from "../../marketplace/single";
import Wishlist from "../../marketplace/wishlist";
import Sell from "../../marketplace/sell";
import List from "../../marketplace/list";
import Edit from "../../marketplace/edit";
import SellerOrders from "../../marketplace/sellerOrders";
import ManageDefaultRun from "../../containers/default-run";
import Rewards from "../../components/Rewards/Rewards";
import Prediction from "../../components/Prediction";
import PredictionPlayNow from "../../components/Prediction/PredictionMobile/PredictionGameStart/PredictionPlayNow";
import PredictionResult from "../../components/Prediction/PredictionMobile/PredictionResult";
import PredictionReview from "../../components/Prediction/PredictionMobile/PredictionReview";
import Coin from "../../containers/coin";
import Deals from "../../components/NewDeals/Deals";
import BlockUser from "../../components/profile/BlockedUsers/BlockUser";
import Banner from "../../components/profile/Banner/Banner";
import ProfileEdit from "../../components/profile/ProfileEdit/ProfileEdit";
import JobPitch from "../../components/pitch-job/JobPitch";
import CoverPhotoCreate from "../../containers/coverPhoto/create/CoverPhotoCreate";
import GetAll from "../../containers/coverPhoto/allcoverphoto/GetAll";
import FavoriteEdit from "../../components/profile/FavoritesEdit/FavoriteEdit";
import DealCongratesPage from "../../components/deal-congo/DealCongratesPage";
import DealDescription from "../../components/deal-description/DealDescription";
import AddressPage from "../../marketplace/addressPage/AddressPage";
import PinPOll from "../../components/pinpoll";
import OrderReceived from "../../marketplace/order";
import OrderStatus from "../../marketplace/orderStatus";
import BuyerNotification from "../../marketplace/buyerNotification/BuyerNotification";
import StatusModal from "../../marketplace/statusModal/StatusModal";
import AppVersion from "../../components/admin/appversion";
import ContactUs from "../../components/contact-us/ContactedtUs";
import Thanks from "../../components/contact-us/Thanks";
import ChatWithadmin from "../../components/chatwithadmin";
import AdminSectionChat from "../../components/chatwithadmin/AdminSectionChat";
import SearchUser from "../../components/chatwithadmin/SearchUser";
import FollowerPage from "../../components/profile/Profile-follow/FollowerPage";
import FollowingPage from "../../components/profile/profile-following/index";
import Challenge from "../../containers/challenge-admin/AllChallenges";
import CreateChallenge from "../../containers/challenge-admin/Create";
import UpdateChallenge from "../../containers/challenge-admin/Update";
import ViewUpdateChallenge from "../../containers/challenge-admin/UpdateAnswer";
import AllChallenges from "../../components/challenge/index";
import BookCricketHome from "../../containers/book-cricket";
import BookCricketBattleList from "../../containers/book-cricket/battle-list";
import BookCricketArena from "../../containers/book-cricket/arena";
import FantasyLeague from "../../components/fantasy-league/index";
import PlayerSelectPage from "../../components/fantasy-league/PlayerSelectPage";
import PlayerScorePage from "../../components/fantasy-league/PlayerScorePage";
import LandingPageCarousel from "../../containers/all-carousel";
import PitchCarousel from "../../containers/all-carousel/pitchCarousel";
import ArenaCarousel from "../../containers/all-carousel/arenaCarousel";

// Runs Transaction page
import RunsTransaction from "../../containers/runs-transaction";

//Post Analytics Page
import AnalyticsPage from "../../containers/analytics";
import PostAnalyticsPage from "../../containers/analytics/PostAnalytics";

// Video ads
import AdminAds from "../../containers/ads/admin";
import AdminCreateAdd from "../../containers/ads/admin/create";
import UserAdvertisments from "../../containers/ads/id";
import Advertismets from "../../containers/ads";
import AboutsUs from "../../components/about";
import AllTeams from "../../containers/single-event/allTeams";
import OldEvent from "../../containers/single-event/previousW";

import ReportUsers from "../../components/group-chat/ReportedUsers";

// group chat
import NewGroupChat from "../../components/group-chat/NewCode/GroupChat";
import Stickers from "../../components/group-chat/Admin/Stickers";
import AdsSection from "../../components/group-chat/Admin/AdsSections";
import MobileChat from "../../components/group-chat/NewCode/MobileChat/MobileChat";
import ResponsiveChat from "../../components/group-chat/NewCode/MobileChat/ResponsiveChats";
import Dugout from "../../components/group-chat/NewCode/Dugout";
import PlayWithComputer from "../../containers/book-cricket/playWithComputer/playWithComputer";
import CreateTournament from "../../containers/fantasy/create/tournament";
import TournamentList from "../../containers/fantasy/tournamentList";
import LeaderboardByMatch from "../../components/fantasy-league/leaderboardByMatch";
import LeaderboardByTournament from "../../components/fantasy-league/leaderboardByTournament";
import GuestPitchSelect from "../../components/new-landing-page/guestPitchSelection";
import ReferEarn from "../../containers/ReferAndEarn/index";

// import Highlights from "../../components/Highlights/Highlights";
import GetSubscriptionHistory from "../../containers/subscription/get_subscriptions_history";
import AdminCreateVoucher from "../../containers/voucher/create/index";
import AdminGetAllCoupons from "../../containers/voucher/get_vocher/index";
const AppRoutes = () => {
  const PublicRoutes = () => (
    <Routes>
      <Route path={"/users"} element={<UserCount />} />
      <Route path={"/privacy"} element={<Privacy />} />
      <Route path={"/refund-policy"} element={<RefundPolicy />} />
      <Route path={"deal-congo"} element={<DealCongratesPage />} />
      <Route path={"/rules"} element={<Rules />} />
      {/* <Route path={"/landing-pitch"} element={<LandingPitch />} /> */}
      <Route path={appRoutes.home} element={<UnAuthContainer />}>
        <Route
          path={"/login"}
          element={
            <UnAuthGuardRoute>
              <SignIn />
            </UnAuthGuardRoute>
          }
        />
        <Route
          path={"/signup"}
          element={
            <UnAuthGuardRoute>
              <SignUp />
            </UnAuthGuardRoute>
          }
        />

        <Route path={"/pitch-preview/:id"} element={<PostPreview />} />
        <Route
          path={"/job-preview/:id"}
          element={
            <UnAuthGuardRoute>
              <JobPreview />
            </UnAuthGuardRoute>
          }
        />
        <Route
          path={"/profile-preview/:id"}
          element={
            <UnAuthGuardRoute>
              <ProfilePreview />
            </UnAuthGuardRoute>
          }
        />
        <Route
          path={"/events-preview/:event_id"}
          element={
            <UnAuthGuardRoute>
              <EventPreview />
            </UnAuthGuardRoute>
          }
        />
        <Route
          path={"/header-guest"}
          element={
            <UnAuthGuardRoute>
              <HeaderGuest />
            </UnAuthGuardRoute>
          }
        />
        <Route
          path={"/landing-page"}
          element={
            <UnAuthGuardRoute>
              <NewLandingPage />
            </UnAuthGuardRoute>
          }
        />
        <Route
          path={"/post-guest"}
          element={
            <UnAuthGuardRoute>
              <PostGuest />
            </UnAuthGuardRoute>
          }
        />
        <Route
          path={"/invite/:uid/:key"}
          element={
            <UnAuthGuardRoute>
              <ReferralPage />
            </UnAuthGuardRoute>
          }
        />
        <Route
          path={"/"}
          exact
          element={<UnAuthGuardRoute>{<LandingPitch />}</UnAuthGuardRoute>}
        />
        <Route
          path={"/about_us"}
          exact
          element={
            <UnAuthGuardRoute>
              <AboutsUs />
            </UnAuthGuardRoute>
          }
        />
        <Route
          path={"/influencer_campaign1"}
          exact
          element={
            <UnAuthGuardRoute>
              <CampaignLandingPage />
            </UnAuthGuardRoute>
          }
        />

        <Route
          path={"/adidas_giveaway_agora"}
          exact
          element={
            <UnAuthGuardRoute>
              <UspLandingpage />
            </UnAuthGuardRoute>
          }
        />
        <Route
          path={"/aurangabad_open_selection_trials_registration_february"}
          exact
          element={
            <UnAuthGuardRoute>
              <SecoundLandingPage />
            </UnAuthGuardRoute>
          }
        />
        <Route
          path={"/indvsNz"}
          exact
          element={
            <UnAuthGuardRoute>
              <IndVSNz />
            </UnAuthGuardRoute>
          }
        />
      </Route>
      <Route path={appRoutes.any} element={<AuthenticatedRoutes />} />
    </Routes>
  );

  const AuthenticatedRoutes = () => (
    <Routes>
      <Route path={appRoutes.home} element={<AuthContainer />}>
        {/* Advertisments Routes */}
        <Route
          path={"/earn-rewards"}
          element={
            <AuthGuardRoute>
              <Advertismets />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/advertisments/:userId"}
          element={
            <AuthGuardRoute>
              <UserAdvertisments />
            </AuthGuardRoute>
          }
        />
        {/* Book Cricket routes start */}
        <Route
          path={"/games/book-cricket"}
          element={
            <AuthGuardRoute>
              <BookCricketHome />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/games/book-cricket/battle-list"}
          element={
            <AuthGuardRoute>
              <BookCricketBattleList />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/games/book-cricket/arena/:id"}
          element={
            <AuthGuardRoute>
              <BookCricketArena />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/games/book-cricket/play-with-computer"}
          element={
            <AuthGuardRoute>
              <PlayWithComputer />
            </AuthGuardRoute>
          }
        />
        {/* Book Cricket routes end */}
        <Route
          path={"/all-events"}
          element={
            <AuthGuardRoute>
              <AllEvents />
            </AuthGuardRoute>
          }
        />

        <Route
          path={"/hashtags"}
          element={
            <AuthGuardRoute>
              <Hashtags />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"profile/chat/:id"}
          element={
            <AuthGuardRoute>
              <ChatWithadmin />
            </AuthGuardRoute>
          }
        />

        <Route
          path={"/pitch-job"}
          element={
            <AuthGuardRoute>
              <JobPitch />
            </AuthGuardRoute>
          }
        />

        <Route
          path={"/events/:event_id"}
          element={
            <AuthGuardRoute>
              <OldEvent />
            </AuthGuardRoute>
          }
        />

        <Route
          path={"/referearn"}
          element={
            <AuthGuardRoute>
              <ReferEarn />
            </AuthGuardRoute>
          }
        />

        <Route
          path={"/tournament/:tournament_id"}
          element={
            <AuthGuardRoute>
              <SingleEvent />
            </AuthGuardRoute>
          }
        />

        <Route
          path={"/AllTeams"}
          element={
            <AuthGuardRoute>
              <AllTeams />
            </AuthGuardRoute>
          }
        />

        <Route
          path={"/event-register/:event_id"}
          element={
            <AuthGuardRoute>
              <EventRegistration />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/registration-successful/:jersey_number"}
          element={
            <AuthGuardRoute>
              <RegistrationSuccessful />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/registration-welcome/:jersey_number"}
          element={
            <AuthGuardRoute>
              <RegistrationWelcome />
            </AuthGuardRoute>
          }
        />

        {/* Admin routes start here */}
        <Route
          path={"/admin"}
          element={
            <AuthGuardRoute>
              <AdminDashboard />
            </AuthGuardRoute>
          }
        >
          <Route
            path={"all-jobs"}
            element={
              <AuthGuardRoute>
                <AllJobsAdmin />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"all-chats"}
            element={
              <AuthGuardRoute>
                <AdminSectionChat />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"searchChat"}
            element={
              <AuthGuardRoute>
                <SearchUser />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"applied-user-list/:id"}
            element={
              <AuthGuardRoute>
                <AppliedUserList />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"job/:id"}
            element={
              <AuthGuardRoute>
                <FindOneJobAdmin />
              </AuthGuardRoute>
            }
          />

          <Route
            path={"find-event/:id"}
            element={
              <AuthGuardRoute>
                <FindEvent />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"find-all-events"}
            element={
              <AuthGuardRoute>
                <FindAllEvents />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"default-users"}
            element={
              <AuthGuardRoute>
                <ManageDefaultUsers />
              </AuthGuardRoute>
            }
          />
          {/* <Route
            path={"coupons"}
            element={
              <AuthGuardRoute>
                <Coupons />
              </AuthGuardRoute>
            }
          /> */}
          <Route
            path={"active-coupons"}
            element={
              <AuthGuardRoute>
                <ActiveCoupons />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"coupons/create"}
            element={
              <AuthGuardRoute>
                <AddCoupon />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"commentary/create"}
            element={
              <AuthGuardRoute>
                <AdminCreateCommentary />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"/admin/videosByAdmin"}
            element={
              <AuthGuardRoute>
                <AdminVideos />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"/admin/videosByUser"}
            element={
              <AuthGuardRoute>
                <UserVideos />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"getallpinpoll"}
            element={
              <AuthGuardRoute>
                <PinPOll />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"app_version"}
            element={
              <AuthGuardRoute>
                <AppVersion />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"all-deals"}
            element={
              <AuthGuardRoute>
                <ManageDeal />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"create-deal"}
            element={
              <AuthGuardRoute>
                <DealCreate />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"deal/:id"}
            element={
              <AuthGuardRoute>
                <FindDeal />
              </AuthGuardRoute>
            }
          />

          <Route
            path={"inactive-deals"}
            element={
              <AuthGuardRoute>
                <DeletedDeal />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"redeem-history"}
            element={
              <AuthGuardRoute>
                <RedeemHistory />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"all-carousel"}
            element={
              <AuthGuardRoute>
                <ManageCarousel />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"create-carousel"}
            element={
              <AuthGuardRoute>
                <CarouselCreate />
              </AuthGuardRoute>
            }
          />

          <Route
            path={"carousel/:id"}
            element={
              <AuthGuardRoute>
                <FindCarousel />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"give-runs/search"}
            element={
              <AuthGuardRoute>
                <FindUser />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"create-event"}
            element={
              <AuthGuardRoute>
                <EventsCreate />
              </AuthGuardRoute>
            }
          />

          <Route
            path={"edit-event-admin/:id"}
            element={
              <AuthGuardRoute>
                <EditEvent />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"edit-deal-admin/:id"}
            element={
              <AuthGuardRoute>
                <EditDeal />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"guestPitchSelection"}
            element={
              <AuthGuardRoute>
                <GuestPitchSelect />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"update-deal"}
            element={
              <AuthGuardRoute>
                <UpdateDeal />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"default-run"}
            element={
              <AuthGuardRoute>
                <ManageDefaultRun />
              </AuthGuardRoute>
            }
          />

          <Route
            path={"update-event"}
            element={
              <AuthGuardRoute>
                <UpdateEvent />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"delete-event"}
            element={
              <AuthGuardRoute>
                <DeleteEvent />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"reffered-users/today"}
            element={
              <AuthGuardRoute>
                <RefferedToday />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"reffered-users"}
            element={
              <AuthGuardRoute>
                <RefferedUsers />
              </AuthGuardRoute>
            }
          />

          <Route
            path={"fantasy"}
            element={
              <AuthGuardRoute>
                <Fantasy />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"tournament"}
            element={
              <AuthGuardRoute>
                <TournamentList />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"fantasy/create"}
            element={
              <AuthGuardRoute>
                <FantasyCreate />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"fantasy/createTournament"}
            element={
              <AuthGuardRoute>
                <CreateTournament />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"fantasy/add-players/:id"}
            element={
              <AuthGuardRoute>
                <FantasyAddPlayer />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"fantasy/view-players/:id"}
            element={
              <AuthGuardRoute>
                <FantasyViewPlayer />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"fantasy-players"}
            element={
              <AuthGuardRoute>
                <FantasyPlayers />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"fantasy-players/create"}
            element={
              <AuthGuardRoute>
                <FantasyPlayersCreate />
              </AuthGuardRoute>
            }
          />
          {/* Admin routes for Advertisments section */}
          <Route
            path={"ads"}
            element={
              <AuthGuardRoute>
                <AdminAds />
              </AuthGuardRoute>
            }
          />

          <Route
            path={"ads/create"}
            element={
              <AuthGuardRoute>
                <AdminCreateAdd />
              </AuthGuardRoute>
            }
          />
          {/* Admin  routes for Subscription section*/}
          <Route
            path={"subscriptions"}
            element={
              <AuthGuardRoute>
                <AdminSubscription />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"subscriptions/create"}
            element={
              <AuthGuardRoute>
                <AdminCreateSubscription />
              </AuthGuardRoute>
            }
          />
          {/*Voucher routes*/}
          <Route
            path={"voucher/create"}
            element={
              <AuthGuardRoute>
                <AdminCreateVoucher />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"voucher/getAllVouchers"}
            element={
              <AuthGuardRoute>
                <AdminGetAllCoupons />
              </AuthGuardRoute>
            }
          />
          {/* Group chats routes */}
          <Route
            path={"groupchats/stickers"}
            element={
              <AuthGuardRoute>
                <Stickers />
              </AuthGuardRoute>
            }
          />

          <Route
            path={"groupchats/adsSection"}
            element={
              <AuthGuardRoute>
                <AdsSection />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"subscriptions/getHistory"}
            element={
              <AuthGuardRoute>
                <GetSubscriptionHistory />
              </AuthGuardRoute>
            }
          />
          {/* Admin routes end here */}

          <Route
            path={"contest"}
            element={
              <AuthGuardRoute>
                <Contest />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"contest/create"}
            element={
              <AuthGuardRoute>
                <ContestCreate />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"contest/add-question/:id"}
            element={
              <AuthGuardRoute>
                <ContestAddQuestion />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"contest/view-questions/:id"}
            element={
              <AuthGuardRoute>
                <ContestViewQuestions />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"contest/edit-answer/:id"}
            element={
              <AuthGuardRoute>
                <ContestEditAnswer />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"contest/update"}
            element={
              <AuthGuardRoute>
                <ContestUpdate />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"contest/answers/:id"}
            element={
              <AuthGuardRoute>
                <ContestAnswers />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"contest/results/:id"}
            element={
              <AuthGuardRoute>
                <ContestResults />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"contest/results/list/:contestid/:listid"}
            element={
              <AuthGuardRoute>
                <ContestResultList />
              </AuthGuardRoute>
            }
          />
          {/*  */}
          <Route
            path={"challenge/create"}
            element={
              <AuthGuardRoute>
                <CreateChallenge />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"challenge/update"}
            element={
              <AuthGuardRoute>
                <UpdateChallenge />
              </AuthGuardRoute>
            }
          />

          <Route
            path={"challenge/viewUpdateChallenge/:id"}
            element={
              <AuthGuardRoute>
                <ViewUpdateChallenge />
              </AuthGuardRoute>
            }
          />
          <Route
            path={"challenge"}
            element={
              <AuthGuardRoute>
                <Challenge />
              </AuthGuardRoute>
            }
          />
          {/*  */}
          <Route
            path={"marketplace/all-sellers"}
            element={
              <AuthGuardRoute>
                <AllSellers />
              </AuthGuardRoute>
            }
          />

          <Route
            path={"marketplace/all-products"}
            element={
              <AuthGuardRoute>
                <AllProducts />
              </AuthGuardRoute>
            }
          />

          <Route
            path={"marketplace/single-seller/:sellerId"}
            element={
              <AuthGuardRoute>
                <SingleSeller />
              </AuthGuardRoute>
            }
          />
        </Route>

        <Route
          path={"/my-profile"}
          element={
            <AuthGuardRoute>
              <OwnProfileDetailsPage />
            </AuthGuardRoute>
          }
        />
        <Route
          path={`/analytics/:id`}
          element={
            <AuthGuardRoute>
              <PostAnalyticsPage />
            </AuthGuardRoute>
          }
        />

        <Route
          path={"/analytics"}
          element={
            <AuthGuardRoute>
              <AnalyticsPage />
            </AuthGuardRoute>
          }
        />

        <Route
          path={"/runs-transaction"}
          element={
            <AuthGuardRoute>
              <RunsTransaction />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/contact-us"}
          element={
            <AuthGuardRoute>
              <ContactUs />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/champhunt-plus"}
          element={
            <AuthGuardRoute>
              <SubsCription />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/payment-status"}
          element={
            <AuthGuardRoute>
              <Payment />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/thank-you"}
          element={
            <AuthGuardRoute>
              <Thanks />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/my-profile/edit"}
          element={
            <AuthGuardRoute>
              <EditProfile />
            </AuthGuardRoute>
          }
        />

        <Route
          path={"/followers-page/:id"}
          element={
            <AuthGuardRoute>
              <FollowerPage />
            </AuthGuardRoute>
          }
        />

        <Route
          path={"/following-page/:id"}
          element={
            <AuthGuardRoute>
              <FollowingPage />
            </AuthGuardRoute>
          }
        />

        <Route
          path={"/news/:article_id"}
          element={
            <AuthGuardRoute>
              <ArticlePage />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/notification"}
          element={
            <AuthGuardRoute>
              <NotificationPage />
            </AuthGuardRoute>
          }
        />

        <Route
          path={"/onboarding"}
          element={
            <AuthGuardRoute>
              <Onboarding />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/pitch"}
          element={
            <AuthGuardRoute>
              <PitchesPage />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/rewards"}
          element={
            <AuthGuardRoute>
              <Rewards />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/challenge"}
          element={
            <AuthGuardRoute>
              <AllChallenges />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/fantasy-league"}
          element={
            <AuthGuardRoute>
              <FantasyLeague />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/all-player"}
          element={
            <AuthGuardRoute>
              <PlayerSelectPage />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/fantasy-league-leaderboardByMatch"}
          element={
            <AuthGuardRoute>
              <LeaderboardByMatch />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/fantasy-league-leaderboardByTournament"}
          element={
            <AuthGuardRoute>
              <LeaderboardByTournament />
            </AuthGuardRoute>
          }
        />
        {/* <Route
          path={"/playerScorePage"}
          element={
            <AuthGuardRoute>
             <PlayerScorePage />
            </AuthGuardRoute>
          }
        /> */}

        <Route
          path={"/marketplace"}
          element={
            <AuthGuardRoute>
              <MarketPlace />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/marketplace/trending"}
          element={
            <AuthGuardRoute>
              <Trending />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/marketplace/cart"}
          element={
            <AuthGuardRoute>
              <AddressProvider>
                <Cart />
              </AddressProvider>
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/marketplace/new"}
          element={
            <AuthGuardRoute>
              <New />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/marketplace/popular"}
          element={
            <AuthGuardRoute>
              <Popular />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/marketplace/category/:name"}
          element={
            <AuthGuardRoute>
              <Category />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/marketplace/single/:id"}
          element={
            <AuthGuardRoute>
              <Single />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/marketplace/wishlist"}
          element={
            <AuthGuardRoute>
              <Wishlist />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/marketplace/sell"}
          element={
            <AuthGuardRoute>
              <Sell />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/marketplace/list"}
          element={
            <AuthGuardRoute>
              <List />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/marketplace/address"}
          element={
            <AuthGuardRoute>
              <AddressProvider>
                <AddressPage />
              </AddressProvider>
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/marketplace/order_received/:id"}
          element={
            <AuthGuardRoute>
              <OrderReceived />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/marketplace/order_status/:id"}
          element={
            <AuthGuardRoute>
              <OrderStatus />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/marketplace/seller_orders"}
          element={
            <AuthGuardRoute>
              <SellerOrders />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/marketplace/status_modal"}
          element={
            <AuthGuardRoute>
              <StatusModal />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/marketplace/buyerNotification"}
          element={
            <AuthGuardRoute>
              <BuyerNotification />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/marketplace/edit/:id"}
          element={
            <AuthGuardRoute>
              <Edit />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"commentary"}
          element={
            <AuthGuardRoute>
              <VideosList />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"commentary/recordVoice/:id"}
          element={
            <AuthGuardRoute>
              <RecordVoice />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/pitch/:pid"}
          element={
            <AuthGuardRoute>
              <PitchPage />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/profile/:uid"}
          element={
            <AuthGuardRoute>
              <OwnProfileDetailsPage />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/search"}
          element={
            <AuthGuardRoute>
              <SearchPage />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/suggestions"}
          element={
            <AuthGuardRoute>
              <SuggestionsPage />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/verify/:uid"}
          element={
            <AuthGuardRoute>
              <ActivateUserPage />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/about-us"}
          element={
            <AuthGuardRoute>
              <AboutUs />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/coin"}
          element={
            <AuthGuardRoute>
              <AdCountProvider>
                <Coin />
              </AdCountProvider>
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/jobs"}
          element={
            <AuthGuardRoute>
              <Jobs />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/highlights"}
          element={
            <AuthGuardRoute>
              <Highlights />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/blockedUsers"}
          element={
            <AuthGuardRoute>
              <BlockUser />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/banner"}
          element={
            <AuthGuardRoute>
              <Banner />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/profile_edit"}
          element={
            <AuthGuardRoute>
              <ProfileEdit />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/favorite_edit/:id"}
          element={
            <AuthGuardRoute>
              <FavoriteEdit />
            </AuthGuardRoute>
          }
        />

        <Route path={"/deals"} element={<Deals />} />
        <Route
          path={"/prediction"}
          element={
            <AuthGuardRoute>
              <Prediction />
            </AuthGuardRoute>
          }
        />

        <Route
          path={"/playNow"}
          element={
            <AuthGuardRoute>
              <PredictionPlayNow />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/results"}
          element={
            <AuthGuardRoute>
              <PredictionResult />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/review"}
          element={
            <AuthGuardRoute>
              <PredictionReview />
            </AuthGuardRoute>
          }
        />

        <Route
          path={"/leader-board"}
          element={
            <AuthGuardRoute>
              <Leaderboard />
            </AuthGuardRoute>
          }
        />

        <Route
          path={"/posted-job"}
          element={
            <AuthGuardRoute>
              <PostedJob />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/post-job"}
          element={
            <AuthGuardRoute>
              <PostJob />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/easy-apply/:id"}
          element={
            <AuthGuardRoute>
              <EasyApply />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/job-description/:id"}
          element={
            <AuthGuardRoute>
              <JobDescription />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/deal-description/:dealId"}
          element={
            <AuthGuardRoute>
              <DealDescription />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/voucher-description/:voucherId"}
          element={
            <AuthGuardRoute>
              <DealDescription />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/coupons-description/:dealId"}
          element={
            <AuthGuardRoute>
              <DealDescription />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/deal-description"}
          element={
            <AuthGuardRoute>
              <DealDescription />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/my-job"}
          element={
            <AuthGuardRoute>
              <MyJob />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"/saved-job"}
          element={
            <AuthGuardRoute>
              <SavedJob />
            </AuthGuardRoute>
          }
        />

        <Route
          path={"/treandin_g"}
          element={
            <AuthGuardRoute>
              <Treanding />
            </AuthGuardRoute>
          }
        />

        <Route
          path={"/groupchat"}
          element={
            <AuthGuardRoute>
              {/* <UserGroupChat /> */}
              {/* <NewGroupChat /> */}
              {/* <ResponsiveChat /> */}
              <Dugout />
              {/* <ChatGroupChat /> */}
            </AuthGuardRoute>
          }
        />

        <Route
          path={"/groupchat/reportedUsers"}
          element={
            <AuthGuardRoute>
              <ReportUsers />
            </AuthGuardRoute>
          }
        />

        <Route path={"*"} element={<NotFound />} />
      </Route>

      <Route path={"/all-advertisments"} element={<AdminAds />} />
      <Route
        path={"/admin"}
        element={
          <AuthGuardRoute>
            <AdminDashboard />
          </AuthGuardRoute>
        }
      >
        <Route
          path={"dashboard"}
          element={
            <AuthGuardRoute>
              <Dashboard />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"all-jobs"}
          element={
            <AuthGuardRoute>
              <AllJobsAdmin />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"applied-user-list/:id"}
          element={
            <AuthGuardRoute>
              <AppliedUserList />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"job/:id"}
          element={
            <AuthGuardRoute>
              <FindOneJobAdmin />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"find-event/:id"}
          element={
            <AuthGuardRoute>
              <FindEvent />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"find-all-events"}
          element={
            <AuthGuardRoute>
              <FindAllEvents />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"default-users"}
          element={
            <AuthGuardRoute>
              <ManageDefaultUsers />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"all-deals"}
          element={
            <AuthGuardRoute>
              <ManageDeal />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"create-deal"}
          element={
            <AuthGuardRoute>
              <DealCreate />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"deal/:id"}
          element={
            <AuthGuardRoute>
              <FindDeal />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"redeem-history"}
          element={
            <AuthGuardRoute>
              <RedeemHistory />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"pitch-carousel"}
          element={
            <AuthGuardRoute>
              <PitchCarousel />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"landingpage-carousel"}
          element={
            <AuthGuardRoute>
              <LandingPageCarousel />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"arenacarousel"}
          element={
            <AuthGuardRoute>
              <ArenaCarousel />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"create-carousel"}
          element={
            <AuthGuardRoute>
              <CarouselCreate />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"create-coverphoto"}
          element={
            <AuthGuardRoute>
              <CoverPhotoCreate />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"getAll-coverphoto"}
          element={
            <AuthGuardRoute>
              <GetAll />
            </AuthGuardRoute>
          }
        />

        <Route
          path={"carousel/:id"}
          element={
            <AuthGuardRoute>
              <FindCarousel />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"give-runs/search"}
          element={
            <AuthGuardRoute>
              <FindUser />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"create-event"}
          element={
            <AuthGuardRoute>
              <EventsCreate />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"update-event"}
          element={
            <AuthGuardRoute>
              <UpdateEvent />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"edit-event-admin/:id"}
          element={
            <AuthGuardRoute>
              <EditEvent />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"edit-deal-admin/:id"}
          element={
            <AuthGuardRoute>
              <EditDeal />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"update-deal"}
          element={
            <AuthGuardRoute>
              <UpdateDeal />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"update-event"}
          element={
            <AuthGuardRoute>
              <UpdateEvent />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"delete-event"}
          element={
            <AuthGuardRoute>
              <DeleteEvent />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"reffered-users/today"}
          element={
            <AuthGuardRoute>
              <RefferedToday />
            </AuthGuardRoute>
          }
        />
        <Route
          path={"reffered-users"}
          element={
            <AuthGuardRoute>
              <RefferedUsers />
            </AuthGuardRoute>
          }
        />
      </Route>
    </Routes>
  );

  const render = () => (
    <Routes>
      {/* <Route path={appRoutes.pitch} element={<AuthGuardRoute><Navigate to={appRoutes.pitch} replace /></AuthGuardRoute>} /> */}
      <Route path={appRoutes.any} element={<PublicRoutes />} />
    </Routes>
  );
  return render();
};

export default AppRoutes;
